import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var BOX_MODEL_REGEX = /-?\d+(\.\d+)?(px|in|mm|cm|pt|%|vw|vh|px)?/g;
var matchBoxModelValue = R.match(BOX_MODEL_REGEX);

var expandBoxModel = function expandBoxModel(model) {
  return function (key, value) {
    var _ref;

    if (value === "auto") return _ref = {}, _ref[model + "Top"] = "auto", _ref[model + "Right"] = "auto", _ref[model + "Bottom"] = "auto", _ref[model + "Left"] = "auto", _ref;
    var match = matchBoxModelValue("" + value);

    if (match) {
      var _ref4;

      var top = match[0];
      var right = match[1] || match[0];
      var bottom = match[2] || match[0];
      var left = match[3] || match[1] || match[0];

      if (key.match(/Horizontal$/)) {
        var _ref2;

        return _ref2 = {}, _ref2[model + "Right"] = right, _ref2[model + "Left"] = left, _ref2;
      }

      if (key.match(/Vertical$/)) {
        var _ref3;

        return _ref3 = {}, _ref3[model + "Top"] = top, _ref3[model + "Bottom"] = bottom, _ref3;
      }

      return _ref4 = {}, _ref4[model + "Top"] = top, _ref4[model + "Right"] = right, _ref4[model + "Bottom"] = bottom, _ref4[model + "Left"] = left, _ref4;
    }

    return value;
  };
};

var _default = expandBoxModel;
exports.default = _default;
export default exports;