import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _castFloat2 from "../utils/castFloat";
import _offsetKeyword2 from "../utils/offsetKeyword";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _castFloat = _interopRequireDefault(_castFloat2);

var _offsetKeyword = _interopRequireDefault(_offsetKeyword2);

var transformTransformOrigin = function transformTransformOrigin(value) {
  return (0, _offsetKeyword.default)(value) || (0, _castFloat.default)(value);
};

var _default = transformTransformOrigin;
exports.default = _default;
export default exports;