import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _flex2 from "./flex";
import _margins2 from "./margins";
import _borders2 from "./borders";
import _paddings2 from "./paddings";
import _objectPosition2 from "./objectPosition";
import _transformOrigin2 from "./transformOrigin";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _flex = _interopRequireDefault(_flex2);

var _margins = _interopRequireDefault(_margins2);

var _borders = _interopRequireDefault(_borders2);

var _paddings = _interopRequireDefault(_paddings2);

var _objectPosition = _interopRequireDefault(_objectPosition2);

var _transformOrigin = _interopRequireDefault(_transformOrigin2);

var shorthands = {
  flex: _flex.default,
  margin: _margins.default,
  marginHorizontal: _margins.default,
  marginVertical: _margins.default,
  padding: _paddings.default,
  paddingHorizontal: _paddings.default,
  paddingVertical: _paddings.default,
  border: _borders.default,
  borderTop: _borders.default,
  borderRight: _borders.default,
  borderBottom: _borders.default,
  borderLeft: _borders.default,
  borderColor: _borders.default,
  borderRadius: _borders.default,
  borderStyle: _borders.default,
  borderWidth: _borders.default,
  objectPosition: _objectPosition.default,
  transformOrigin: _transformOrigin.default
};
/**
 * Transforms style key-value
 *
 * @param {String} key style key
 * @param {String} value style value
 * @returns {String | Number} transformed style values
 */

var expandStyle = function expandStyle(key, value) {
  var _ref;

  return shorthands[key] ? shorthands[key](key, value) : (_ref = {}, _ref[key] = value, _ref);
};
/**
 * Expand the shorthand properties.
 *
 * @param { Object } style object
 * @returns { Object } expanded style object
 */


var expand = function expand(style) {
  if (!style) return style;
  var propsArray = Object.keys(style);
  var resolvedStyle = {};

  for (var i = 0; i < propsArray.length; i += 1) {
    var key = propsArray[i];
    var value = style[key];
    var extended = expandStyle(key, value);
    var keys = Object.keys(extended);

    for (var j = 0; j < keys.length; j += 1) {
      var propName = keys[j];
      var propValue = extended[propName];
      resolvedStyle[propName] = propValue;
    }
  }

  return resolvedStyle;
};

var _default = expand;
exports.default = _default;
export default exports;