import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _extends4 from "@babel/runtime/helpers/extends";

var _extends = "default" in _extends4 ? _extends4.default : _extends4;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _mediaEngine3 from "media-engine";

var _mediaEngine2 = "default" in _mediaEngine3 ? _mediaEngine3.default : _mediaEngine3;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _extends3 = _interopRequireDefault(_extends);

var R = _interopRequireWildcard(_ramda);

var _mediaEngine = _interopRequireDefault(_mediaEngine2);
/**
 * Resolves media queries in styles object
 *
 * @param {Object} container
 * @param {Object} styles object
 */


var resolveMediaQueries = function resolveMediaQueries(container, styles) {
  return Object.keys(styles).reduce(function (acc, key) {
    var _extends2;

    if (/@media/.test(key)) {
      var _matchMedia;

      return (0, _extends3.default)({}, acc, (0, _mediaEngine.default)((_matchMedia = {}, _matchMedia[key] = styles[key], _matchMedia), container));
    }

    return (0, _extends3.default)({}, acc, (_extends2 = {}, _extends2[key] = styles[key], _extends2));
  }, {});
};

var _default = R.curryN(2, resolveMediaQueries);

exports.default = _default;
export default exports;