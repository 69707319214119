import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _expand2 from "./expand";
import _flatten2 from "./flatten";
import _transform2 from "./transform";
import _mediaQueries2 from "./mediaQueries";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _expand = _interopRequireDefault(_expand2);

var _flatten = _interopRequireDefault(_flatten2);

var _transform = _interopRequireDefault(_transform2);

var _mediaQueries = _interopRequireDefault(_mediaQueries2);
/**
 * Filter styles with `none` value
 *
 * @param {Object} style object
 * @returns {Object} style without none values
 */


var filterNoneValues = R.reject(R.equals("none"));
/**
 * Resolves styles
 *
 * @param {Object} container
 * @param {Object} style object
 * @returns {Object} resolved style object
 */

var resolveStyles = function resolveStyles(container, style) {
  return R.compose((0, _transform.default)(container), _expand.default, (0, _mediaQueries.default)(container), filterNoneValues, _flatten.default)(style);
};

var _default = R.curryN(2, resolveStyles);

exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;