import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);
/* eslint-disable import/prefer-default-export */


var matchNumber = R.when(R.is(String), R.test(/^-?\d*\.?\d*$/));

var castFloat = function castFloat(value) {
  if (typeof value !== "string") return value;
  if (matchNumber(value)) return parseFloat(value, 10);
  return value;
};

var _default = castFloat;
exports.default = _default;
export default exports;