import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _boxModel2 from "./boxModel";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _boxModel = _interopRequireDefault(_boxModel2);

var expandMargin = (0, _boxModel.default)("margin");
var _default = expandMargin;
exports.default = _default;
export default exports;